import {tns} from "./vendor/tiny-slider/tiny-slider";

var slider = tns({
  container: '#slider-discover',
  items: 1,
  controls: false,
  navPosition: "bottom",
  autoHeight: true,
  mouseDrag: true,
  mode: "gallery"
});

var slider = tns({
  container: '#slider-listen',
  items: 1,
  controls: false,
  navPosition: "bottom",
  autoHeight: true,
  mouseDrag: true,
  mode: "gallery"
});

var slider = tns({
  container: '#slider-experience',
  items: 1,
  controls: false,
  navPosition: "bottom",
  autoHeight: true,
  mouseDrag: true,
  mode: "gallery"
});

//////

import "./components/smooth-scroll.min";

// smooth-scroll
var scroll = new SmoothScroll('a[href*="#"]', {
  offset: 0
} );

import "./components/audio-player";
