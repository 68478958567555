const $audioPlayerWrapper = document.querySelectorAll('.audioplayer-wrapper');

Array.prototype.forEach.call($audioPlayerWrapper, $audioPlayer => {
    const $playButton = $audioPlayer.querySelector('.play');
    const $stopButton = $audioPlayer.querySelector('.stop');
    const $html5Player = $audioPlayer.querySelector('.audioplayer');

    const stopActivePlayer = () => {
        Array.prototype.forEach.call($audioPlayerWrapper, $stopAudioPlayer => {
            const $player = $stopAudioPlayer.querySelector('.audioplayer');
            const $stopPlayButton = $stopAudioPlayer.querySelector('.play');

            $player.pause();
            $player.currentTime = 0;

            $stopPlayButton.classList.remove('blink');
        });
    };

    const play = () => {
        stopActivePlayer();

        $html5Player.play();

        $playButton.classList.add('blink');
    };

    const stop = () => {
        $html5Player.pause();

        $html5Player.currentTime = 0;

        $playButton.classList.remove('blink');
    };

    if ($playButton && $stopButton) {
        $playButton.addEventListener('click', play);
        $stopButton.addEventListener('click', stop);
    }
});
